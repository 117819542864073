 (function ($) {
 	var cookie_approval = getCookie('cookie_approval_krynicki');
 	if (cookie_approval != undefined){
 		$('.cookies__cont').css('display', 'none');
 	}

 	$('.cookies__close').click(function(e){
 		e.preventDefault();
 		setCookie('cookie_approval_krynicki', true, 60, 2);
 		$('.cookies__cont').fadeOut();
 	});
 })(jQuery);

 function getCookie(c_name) {
 	var i, x, y, ARRcookies = document.cookie.split(";");
 	for (i = 0; i < ARRcookies.length; i++) {
 		x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
 		y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
 		x = x.replace(/^\s+|\s+$/g, "");
 		if (x == c_name) {
 			return unescape(y);
 		}
 	}
 }

 function setCookie(c_name, value, exdays) {
 	var exdate = new Date();
 	exdate.setDate(exdate.getDate() + exdays);
 	var c_value = escape(value) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString());
 	document.cookie = c_name + "=" + c_value + '; path=/';
 }
