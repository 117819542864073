/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages

                 WebFontConfig = {
                    google: {
                                families:   [ 'Raleway:400,300,100,200,500,600,700,800,900:latin,latin-ext', 'Roboto:400,300,100,500,700,900:latin,latin-ext' ]
                            }
                  };
                  (function() {
                    var wf = document.createElement('script');
                    wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
                    wf.type = 'text/javascript';
                    wf.async = 'true';
                    var s = document.getElementsByTagName('script')[0];
                    s.parentNode.insertBefore(wf, s);
                  })();

                if ($('.wp-admin').length < 1) {
                    // Header search trigger click event
                    $('.header__bottom .search__trigger, .search__close').click(function (e) {
                        e.preventDefault();
                        $(this).closest('.search__box').toggleClass('open');
                    });

                    // Header mobile menu trigger
                    $('.nav__trigger').click(function (e) {
                        e.preventDefault();
                        $('body').toggleClass('no__scroll');
                    });

                    $(".mobile-menu").hide();
                    $(".nav__trigger").click(function(){
                      $(".mobile-menu").slideToggle("normal");
                      return false;
                    });

                    var cookieFont = getCookie('cookie_font_krynicki');
                    if (cookieFont !== undefined) {
                        $.each( $('.font__items li') ,function(){
                            $(this).find('[data-fontsize="' + cookieFont + '"]').closest('li').addClass('current').siblings().removeClass('current');
                            $('html').attr('data-fontsize', cookieFont);
                        });
                    }

                    var cookieTheme = getCookie('cookie_theme_krynicki');
                    if (cookieTheme !== undefined) {
                        $.each( $('.theme__items li') ,function(){
                            $(this).find('[data-theme="' + cookieTheme + '"]').closest('li').addClass('current').siblings().removeClass('current');
                            $('html').attr('data-theme', cookieTheme);
                        });
                    }

                    // Font items click event
                    $('.font__items a').click(function (e) {
                        var _font = $(this).attr('data-fontsize');
                        $('.font__items li').removeClass('current');
                        setCookie('cookie_font_krynicki', _font, 30);
                        $('html').attr('data-fontsize', _font);
                        $('body').find('[data-equalizer-watch]').css('height', 'auto');
                        $(document).foundation('equalizer', 'reflow');
                        $.each( $('.font__items li') ,function(a){
                            $('body').find('[data-fontsize="' + _font + '"]').closest('li').addClass('current').siblings().removeClass('current');
                        });
                    });

                    // Themes items click event
                    $('.theme__items a').click(function (e) {
                        e.preventDefault();
                        var _theme = $(this).attr('data-theme');
                        $('.theme__items li').removeClass('current');
                        $('html').attr('data-theme', _theme);
                        setCookie('cookie_theme_krynicki', _theme, 30);

                        $.each( $('.font__items li') ,function(a){
                            $('body').find('[data-theme="' + _font + '"]').closest('li').addClass('current').siblings().removeClass('current');
                        });
                    });

                    // Scroll top
                    $('.scroll__top').click(function (e) {
                        e.preventDefault();
                        $('body,html').stop().animate({
                            scrollTop: 0
                        }, 300);
                    });

                    $( ".sub__nav ul ul" ).not('.show').hide();
                    $( ".sub__nav ul ul ul" ).show();
                    $( ".sub__nav ul li.current-menu-item").parent('ul').show();
					$( ".sub__nav ul li.current-menu-item").parent('ul').parent('li').parent('ul').show();
                    $( ".sub__nav ul li.current-menu-item").children('ul').show();
                    $( ".sub__nav__lvl1" ).on( 'click', '.menu-item-has-children span', function() {
                      $(this).next('ul').slideToggle();
                      return false;
                    });

                    $( ".mobile-menu ul ul" ).hide();
                    $( ".mobile-menu ul ul ul" ).show();
                    $( ".mobile-menu ul li.current_page_item").parent('ul').show();
                    $( ".mobile-menu ul li.current_page_ancestor, .mobile-menu ul li.current-menu-item").children('ul').show();
                    $( ".mobile-menu ul" ).on( 'click', '.menu-item-has-children span', function() {
                      $(this).next('ul').slideToggle();
                      return false;
                    });

					$( ".sharelinks-popup" ).hide();
					$('.sharelinks-content').click(function () {
                        $( ".sharelinks-popup" ).fadeToggle();
                    });

                    // Pdf creator
                    var doc = new jsPDF();
                    var specialElementHandlers = {
                        '#editor': function (element, renderer) {
                            return true;
                        }
                    };
                    $('.download--pdf').click(function () {
                        doc.fromHTML($('#pdf__element').html(), 15, 15, {
                            'width': 170, 'elementHandlers': specialElementHandlers
                        });
                        doc.save('sample-file.pdf');
                    });

                    $( '.sub__nav__lvl1 .menu-item-has-children a' ).not( '.sub-menu li a' ).after( '<span><i class="fa fa-caret-down"></i></span>' );
                    $( '.mobile-menu ul .menu-item-has-children a' ).not( '.sub-menu li a' ).after( '<span><i class="fa fa-caret-down"></i></span>' );

                    // Google Maps
                    if ($('.map').length) {

                        var maps = [];

                        var styles = [
                        {
                            "stylers": [
                            ]
                        }
                        ];

                        $('.map').each(function () {
                            var _el = $(this);

                            var latLng = new google.maps.LatLng(_el.attr('data-lat'), _el.attr('data-lang')),
                            _url = (typeof _el.attr('data-marker') !== undefined) ? _el.attr('data-marker') : '../images/marker.png',
                            _title = (typeof _el.attr('data-title') !== undefined) ? _el.attr('data-title') : 'Krynicki',
                            _zoom = (typeof _el.attr('data-title') !== undefined) ? parseInt(_el.attr('data-zoom')) : 15;


                            var mapOptions = {
                                center: latLng,
                                zoom: _zoom,
                                scrollwheel: false,
                                mapTypeId: google.maps.MapTypeId.ROADMAP
                            };

                            var map = new google.maps.Map($(this).get(0),
                              mapOptions);

                            var styledMap = new google.maps.StyledMapType(styles,
                              { name: "Styled Map" });

                            map.mapTypes.set('map-canvas', styledMap);
                            map.setMapTypeId('map-canvas');

                            var image = {
                                url: _url,
                                size: new google.maps.Size(57, 64),
                                origin: new google.maps.Point(0, 0),
                                anchor: new google.maps.Point(28, 64)
                            };

                            var marker = new google.maps.Marker({
                                position: latLng,
                                map: map,
                                title: _title,
                                icon: image
                            });

                            google.maps.event.addDomListener(window, "resize", function () {
                                var center = map.getCenter();
                                google.maps.event.trigger(map, "resize");
                                map.setCenter(center);
                            });
                            maps.push(map);
                        });
                    }

                    // Tabs
                    if ($('.tabs').length) {
                        var $tabs = $('.tabs');
                        $tabs.find('a').click(function (e) {
                            e.preventDefault();
                            if (!$(this).parent().hasClass('active')) {
                                var _el = $(this), _tabsCont = _el.closest('.tabs').siblings('.tabs-content');
                                _el.parent().siblings().removeClass('active');
                                _el.parent().addClass('active');
                                _tabsCont.find('.content').stop().slideUp(300, function () {
                                    _tabsCont.find(_el.attr('href')).stop().slideDown(300);
                                });
                            }
                        });

                        var _totalSum, _width;
                        $tabs = $('.tabs');

                        $(window).resize(function () {
                            $tabs.each(function () {
                                _totalSum = 0;
                                _width = $(this).width();
                                $(this).find('li:not(".slick-cloned")').each(function () {
                                    _totalSum += parseInt($(this).width(), 10);
                                });

                                if (_totalSum > _width && !$(this).hasClass('slick-initialized')) {
                                    $(this).slick({
                                        dots: false,
                                        swipe: false,
                                        infinite: true,
                                        slidesToShow: 1,
                                        speed: 300,
                                        centerMode: true,
                                        variableWidth: true
                                    });
                                } else if (_totalSum <= _width && $(this).hasClass('slick-initialized')) {
                                    $(this).slick('unslick');
                                }
                            });
                        }).resize();

                    }

                    //ALM - sprawdzanie czy puste
                    $.fn.almEmpty = function(alm){
                        $('.alm-listing').html('<li><p>'+ brak_wpisow +'</p></li>');
                    };
                    // Custom Select
                    if ($('.form__element select').length) {
                        $('.form__element select').customSelect();
                    }
                    if ($('.multiselect').length) {
                        $('.multiselect').each(function () {
                            var _el = $(this),
                            _currentValue = _el.find('a'),
                            _savedValue = _currentValue.find('span').html();

                            _currentValue.click(function (e) {
                                e.preventDefault();
                                _el.toggleClass('open');

                                $('html,body').click(function (e) {
                                    if ($(e.target).closest(_el).length < 1) {
                                        _el.removeClass('open');
                                    }
                                });
                            });

                            _el.find('input').change(function () {
                                var value = '';
                                _el.find('input:checked').each(function (i, item) {
                                    value += $(this).siblings('label').html() + ', ';
                                });
                                if (value === '') {
                                    _currentValue.find('span').html(_savedValue);
                                } else {
                                    _currentValue.find('span').html(value.substr(0, value.length - 2));
                                }
                            });
                        });
                    }

                    // Slider
                    if ($('.slider__slick').length) {

                        $('.slider__slick').each(function () {
                            var $slider = $(this),
                            $container = $slider.parent();
                            $slider
                            .on('init', function (e, slick) {
                                $container.find('.slider__totalSlides').html(slick.slideCount);
                            })
                            .slick({
                                arrows: false,
                                loop: true,
                                slidesToShow: 1,
                                fade: true
                            })
                            .on('beforeChange', function (e, slick, currentSlide, nextSlide) {
                                $container.find('.slider__currentSlide').html(nextSlide + 1);
                            });

                            $container.find('.bttn__prev').click(function (e) {
                                e.preventDefault();
                                $slider.slick('slickPrev');
                            });
                            $container.find('.bttn__next').click(function (e) {
                                e.preventDefault();
                                $slider.slick('slickNext');
                            });
                        });
                    }

                    // Sub Nav RWD
                    // wywalone dla Idź na wersji bez css
                    /*if ($('.sub__nav').length) {
                        var $sub = $('.sub__nav');
                        var _select = $('<select />').appendTo($sub);
                        _select.addClass('sub__nav__select');
                        $("<option />", {
                            "selected": "selected",
                            "disabled": "disabled",
                            "hidden": "hidden",
                            "value": "",
                            "text": "Idź do..."
                        }).appendTo(_select);

                        $sub.find('a').each(function () {
                            var _el = $(this);
                            var _opt = $("<option />", {
                                "value": _el.attr("href"),
                                "text": _el.text()
                            }).appendTo(_select);

                            if (_el.closest('.sub__nav__lvl2').length) {
                                _opt.addClass('option__lvl2');
                            }
                        });
                        _select.on('change', function() {
                            this.options[this.selectedIndex].value && (window.location = this.options[this.selectedIndex].value);
                        });
                        _select.customSelect();
                    }*/

                    /*
                    * W zależnosci od zakladki podmieniamy adres
                     */
                    $('.tab-title a').on( 'click', function(){
                        var url = $(this).data('url');
                        $('#button_raporty').prop( "href" , url );

                    } );

                    // Videos

                    if ($('.box--media').length) {
                        $('.box--media').click(function (e) {
                            e.preventDefault();
                            var _box = $(this),
                            _cont,
                            id = null;

                            id = _box.attr('href').match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/);

                            if (id != null) {
                                _cont = $('<div class="video__cont" />').appendTo(_box);
                                if (id[3].indexOf('youtu') > -1) {
                                    _cont.append('<iframe width="100%" src="http://www.youtube.com/embed/' +
                                      id[6] + '?autoplay=1&controls=0&showinfo=0&rel=0&v=' + id[6] + '" frameborder="0" allowfullscreen class="videoContainer__video"></iframe>');
                                } else if (id[3].indexOf('vimeo') > -1) {
                                    _cont.append('<iframe src="http://player.vimeo.com/video/' + id[6] +
                                      '?autoplay=1" width="100%" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen class="videoContainer__video"></iframe>');
                                }
                                _cont.fadeIn();
                            }

                        });
                    }
                }

				if ( $('#notowanie_ajax').length > 0) {

				    $('#spinner').show();
				    $('#notowanie_ajax').css('opacity','.2');

				    // Information of our Request
				    var data = {
				        'action': 'ajax_notowanie',
						'button': $('#notowanie_ajax').data( 'button' ),
						'button_url': $('#notowanie_ajax').data( 'button-url' ),
						'header': $('#notowanie_ajax').data( 'header' ),
				    };

				    // The variable ajax_url should be the URL of the admin-ajax.php file
				    $.post( ajax_url, data, function(response) {
				        $('#notowanie_ajax').css('opacity','1');
				        $('#spinner').hide();
				        $('#notowanie_ajax').html(response);
				        //console.log(response);
				    });
				}

				$( '#raporty_ajax' ).on( 'submit', function(){
				    $('.spinner').show();
				    $('#currentraport__list, #periodicraport__list').css('opacity','.2');
				    var search = $('#search-input').val();

				    var data = {
				        'action': 'ajax_relacje_biezace',
				        's' : search
				    };

				    // The variable ajax_url should be the URL of the admin-ajax.php file
				    $.post( ajax_url, data, function(response) {
				        $('#currentraport__list').css('opacity','1');
				        $('.spinner').hide();
				        $('#currentraport__list').html(response);
				        //console.log(response);
				    });

				    var data = {
				        'action': 'ajax_relacje_okresowe',
				        's' : search
				    };

				    // The variable ajax_url should be the URL of the admin-ajax.php file
				    $.post( ajax_url, data, function(response) {
				        $('#periodicraport__list').css('opacity','1');
				        $('.spinner').hide();
				        $('#periodicraport__list').html(response);
				        //console.log(response);
				    });

				    return false;
				} );

				$( '#raporty_ajax a' ).on( 'click', function(){
				    $('.spinner').show();
				    $('#currentraport__list, #periodicraport__list').css('opacity','.2');
				    var search = $('#search-input').val();

				    var data = {
				        'action': 'ajax_relacje_biezace',
				        's' : search
				    };

				    // The variable ajax_url should be the URL of the admin-ajax.php file
				    $.post( ajax_url, data, function(response) {
				        $('#currentraport__list').css('opacity','1');
				        $('.spinner').hide();
				        $('#currentraport__list').html(response);
				        //console.log(response);
				    });

				    var data = {
				        'action': 'ajax_relacje_okresowe',
				        's' : search
				    };

				    // The variable ajax_url should be the URL of the admin-ajax.php file
				    $.post( ajax_url, data, function(response) {
				        $('#periodicraport__list').css('opacity','1');
				        $('.spinner').hide();
				        $('#periodicraport__list').html(response);
				        //console.log(response);
				    });

				    return false;
				} );

				$( '#filterdata' ).on( 'submit', function(){
				    $('.spinner').show();
				    $('#filter_content').css('opacity','.2');
				    var search = $('#tytul').val();
				    var czas = $('#data').val();
				    var post_type = $('#filterdata').data('post_type');
				    var template = $('#filterdata').data('template');
				    var checkbox = $('.multiselect__currentvalue span').html();

				    console.log( checkbox );

				    var data = {
				        'action': 'ajax_filtrowanie',
				        's' : search,
				        'czas' : czas,
				        'post_type' : post_type,
				        'template' : template
				    };

				    // The variable ajax_url should be the URL of the admin-ajax.php file
				    $.post( ajax_url, data, function(response) {
				        $('#filter_content').css('opacity','1');
				        $('.spinner').hide();
				        $('#filter_content').html(response);
				        var data = $('#ajax-load-more .alm-listing').data();
				        $.fn.almFilter( 'fade', 300, data);
				        //console.log(response);
				    });

				    return false;
				} );
            },
            finalize: function () {
				$(document).foundation('tooltip', 'reflow');
				var id_lupa = '#'+ $( '.icon-search' ).data( 'selector' );
				$( id_lupa ).addClass( 'toolszukaj' );
                // ## przeniesione z konkretnej podstrony aby mozna bylo uzywac w flexible content
                // ## w finalize a nie init bo wtedy przestaje dzialac tabela  w informacje-finansowe
                if ($('#myChart0').length > 0) {
                    new LineChartInit("myChart0", lineChartData0);
                }
                if ($('#myChart1').length > 0) {
                    new LineChartInit("myChart1", lineChartData1);
                }
                if ($('#myChart2').length > 0) {
                    new LineChartInit("myChart2", lineChartData2);
                }

                if ($('#myChart3').length > 0) {
                    new DoughnutChartInit('myChart3', doughnutChartData3);
                }

                if ($('#myChart4').length > 0) {
                    new DoughnutChartInit('myChart4', doughnutChartData4);
                }
                if ($('#myChart5').length > 0) {
                    new DoughnutChartInit('myChart5', doughnutChartData5);
                }

                if ($('#myChart6').length > 0) {
                    new BarChartInit('myChart6', barChartData6);
                }

                if ($('#myChart7').length > 0) {
                    new BarChartInit('myChart7', barChartData7);
                }

                if ($('#myChart8').length > 0) {
                    new BarChartInit('myChart8', barChartData8);
                }

                if ($('.wp-admin').length < 1) {
                    $(document).foundation({
                      equalizer : {
                        equalize_on_stack: true
                      }
                    });
                }


            }
        },
        // Home page
        'modules': {
            init: function () {

                var lineChartData = {
                    labels: ["2015 12-12", "2015 12-12", "2015 12-12", "2015 12-12", "2015 12-12", "2015 12-12", "2015 12-12"],
                    datasets: [{
                        label: "My First dataset",
                        fillColor: "rgba(220,220,220,0)",
                        strokeColor: "#13854f",
                        pointColor: "rgba(220,220,220,1)",
                        pointStrokeColor: "#fff",
                        pointHighlightFill: "#fff",
                        pointHighlightStroke: "rgba(220,220,220,1)",
                        data: [20, 30, 25, 34, 23, 12, 20]
                    }]
                };

                var doughnutChartData = [
                {
                    value: 300,
                    color: "#13854f",
                    highlight: "#13854f",
                    label: "Label 1"
                },
                {
                    value: 50,
                    color: "#20925C",
                    highlight: "#20925C",
                    label: "Label 2"
                },
                {
                    value: 100,
                    color: "#2D9F69",
                    highlight: "#2D9F69",
                    label: "Label 3"
                },
                {
                    value: 50,
                    color: "#46B882",
                    highlight: "#46B882",
                    label: "Label 4"
                },
                {
                    value: 100,
                    color: "#53C58F",
                    highlight: "#53C58F",
                    label: "Label 5"
                }
                ];

                var doughnutChartData2 = [
                {
                    value: 300,
                    color: "#13854f",
                    highlight: "#13854f",
                    label: "Label 1"
                },
                {
                    value: 50,
                    color: "#20925C",
                    highlight: "#20925C",
                    label: "Label 2"
                },
                {
                    value: 100,
                    color: "#2D9F69",
                    highlight: "#2D9F69",
                    label: "Label 3"
                },
                {
                    value: 50,
                    color: "#46B882",
                    highlight: "#46B882",
                    label: "Label 4"
                },
                {
                    value: 100,
                    color: "#53C58F",
                    highlight: "#53C58F",
                    label: "Label 5"
                }
                ];


                var barChartData = {
                    labels: ["2015 12-12", "2015 12-12", "2015 12-12", "2015 12-12", "2015 12-12", "2015 12-12", "2015 12-12"],
                    datasets: [{
                        label: "Label 1",
                        fillColor: "#13854f",
                        strokeColor: "#13854f",
                        pointColor: "#13854f",
                        pointStrokeColor: "#13854f",
                        pointHighlightFill: "#13854f",
                        pointHighlightStroke: "#13854f",
                        data: [20, 30, 25, 34, 23, 12, 20]
                    },
                    {
                        label: "Label 2",
                        fillColor: "#2D9F69",
                        strokeColor: "#2D9F69",
                        pointColor: "#2D9F69",
                        pointStrokeColor: "#2D9F69",
                        pointHighlightFill: "#2D9F69",
                        pointHighlightStroke: "#2D9F69",
                        data: [28, 48, 40, 19, 86, 27, 90]
                    }]

                };

                var barChartData2 = {
                    labels: ["2015 12-12", "2015 12-12", "2015 12-12", "2015 12-12", "2015 12-12", "2015 12-12", "2015 12-12"],
                    datasets: [{
                        label: "Label 1",
                        fillColor: "#13854f",
                        strokeColor: "#13854f",
                        pointColor: "#13854f",
                        pointStrokeColor: "#13854f",
                        pointHighlightFill: "#13854f",
                        pointHighlightStroke: "#13854f",
                        data: [20, 30, 25, 34, 23, 12, 20]
                    },
                    {
                        label: "Label 2",
                        fillColor: "#2D9F69",
                        strokeColor: "#2D9F69",
                        pointColor: "#2D9F69",
                        pointStrokeColor: "#2D9F69",
                        pointHighlightFill: "#2D9F69",
                        pointHighlightStroke: "#2D9F69",
                        data: [28, 48, 40, 19, 86, 27, 90]
                    }]

                };



                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        'page_template_informacje_finansowe_tpl': {
            init: function () {
                new FinancialInformationFiltering();
                new DateTimePickerInit('Y', 'pl');
                /*$('.table__cont--financial').mCustomScrollbar({
                    theme: 'minimal-dark',
                    axis: "x",
                    setWidth: '100%',
                    mouseWheel: { enable: false }
                });

                var _winScroll, _bar = $('#mCSB_1_scrollbar_horizontal');
                $(window).scroll(function () {
                    _winScroll = $(window).scrollTop() + $(window).height();

                    if (_winScroll >= $('.table__cont--financial').offset().top && _winScroll <= $('.table__cont--financial').offset().top + $('.table__cont--financial').height()) {
                        _bar.css({
                            'position': 'fixed',
                            'bottom': '0',
                            'left': $('.table__cont--financial').offset().left,
                            'width': $('.table__cont--financial').width()
                        });
                    } else {
                        _bar.css({
                            'position': 'absolute',
                            'bottom': '-4px',
                            'left': '0',
                            'width': '100%'
                        });
                    }
                });
                $(window).resize(function () {
                    $(window).scroll();
                });
				*/
            },
            finalize: function () {
            }
        },
		'page_template_notowania_tpl': {
			init: function () {
				//RelationsFiltering();
				var step = 3;
				var data_today_values = $('#myChart').data('today');
				var max_of_today = Math.max.apply(Math, data_today_values);
				var min_of_today = Math.min.apply(Math, data_today_values);

				var data_today_dates = $('#myChart').data('today_dates');

				var data_week_values = $('#myChart').data('tydzien');
				var data_week_dates = $('#myChart').data('tydzien_dates');

				var data_month_values = $('#myChart').data('miesiac');
				var data_month_dates = $('#myChart').data('miesiac_dates');

				var data_quarter_values = $('#myChart').data('kwartal');
				var data_quarter_dates = $('#myChart').data('kwartal_dates');

				var data_year_values = $('#myChart').data('rok');
				var data_year_dates = $('#myChart').data('rok_dates');

				var all = {
					labels: data_today_dates,
					datasets: [{
						label: "My First dataset",
						fillColor: "rgba(220,220,220,0)",
						strokeColor: "#13854f",
						pointColor: "rgba(220,220,220,1)",
						pointStrokeColor: "#fff",
						pointHighlightFill: "#fff",
						pointHighlightStroke: "rgba(220,220,220,1)",
						data: data_today_values,
					}]
				};
				var month = {
					labels: data_month_dates,
					datasets: [{
						label: "Month dataset",
						fillColor: "rgba(220,220,220,0)",
						strokeColor: "#13854f",
						pointColor: "rgba(220,220,220,1)",
						pointStrokeColor: "#fff",
						pointHighlightFill: "#fff",
						pointHighlightStroke: "rgba(220,220,220,1)",
						data: data_month_values
					}]
				};
				var quarter = {
					labels: data_quarter_dates,
					datasets: [{
						label: "Month dataset",
						fillColor: "rgba(220,220,220,0)",
						strokeColor: "#13854f",
						pointColor: "rgba(220,220,220,1)",
						pointStrokeColor: "#fff",
						pointHighlightFill: "#fff",
						pointHighlightStroke: "rgba(220,220,220,1)",
						data: data_quarter_values
					}]
				};
				var halfyear = {
					labels: data_year_dates,
					datasets: [{
						label: "Month dataset",
						fillColor: "rgba(220,220,220,0)",
						strokeColor: "#13854f",
						pointColor: "rgba(220,220,220,1)",
						pointStrokeColor: "#fff",
						pointHighlightFill: "#fff",
						pointHighlightStroke: "rgba(220,220,220,1)",
						data: data_year_values
					}]
				};


				var lineChart = new LineChartInit_relacje("myChart", all);
				$('a[data-chartfilter]').click(function (e) {
					e.preventDefault();
					var periodName = '', $parent;
					$(this).closest('li').siblings().removeClass('current');
					$(this).closest('li').addClass('current');
					periodName = $(this).attr('data-chartfilter');
					if (lineChart !== undefined) {
						lineChart.destroy()
					}
					if (periodName === 'month') {
						lineChart = new LineChartInit_relacje("myChart", month);
					} else if (periodName === 'quarter') {
						lineChart = new LineChartInit_relacje("myChart", quarter);
					} else if (periodName === 'halfyear') {
						lineChart = new LineChartInit_relacje("myChart", halfyear);
					} else {
						lineChart = new LineChartInit_relacje("myChart", all);
					}
				});

			},
			finalize: function () {
			}
		},
        'page_template_relacje_inwestorskie_tpl': {
            init: function () {
                //RelationsFiltering();
                var step = 3;
                var data_today_values = $('#myChart').data('today');
                var max_of_today = Math.max.apply(Math, data_today_values);
                var min_of_today = Math.min.apply(Math, data_today_values);

                var data_today_dates = $('#myChart').data('today_dates');

                var data_week_values = $('#myChart').data('tydzien');
                var data_week_dates = $('#myChart').data('tydzien_dates');

                var data_month_values = $('#myChart').data('miesiac');
                var data_month_dates = $('#myChart').data('miesiac_dates');

                var data_quarter_values = $('#myChart').data('kwartal');
                var data_quarter_dates = $('#myChart').data('kwartal_dates');

                var data_year_values = $('#myChart').data('rok');
                var data_year_dates = $('#myChart').data('rok_dates');

                var all = {
                    labels: data_today_dates,
                    datasets: [{
                        label: "My First dataset",
                        fillColor: "rgba(220,220,220,0)",
                        strokeColor: "#13854f",
                        pointColor: "rgba(220,220,220,1)",
                        pointStrokeColor: "#fff",
                        pointHighlightFill: "#fff",
                        pointHighlightStroke: "rgba(220,220,220,1)",
                        data: data_today_values,
                    }]
                };
                var month = {
                    labels: data_month_dates,
                    datasets: [{
                        label: "Month dataset",
                        fillColor: "rgba(220,220,220,0)",
                        strokeColor: "#13854f",
                        pointColor: "rgba(220,220,220,1)",
                        pointStrokeColor: "#fff",
                        pointHighlightFill: "#fff",
                        pointHighlightStroke: "rgba(220,220,220,1)",
                        data: data_month_values
                    }]
                };
                var quarter = {
                    labels: data_quarter_dates,
                    datasets: [{
                        label: "Month dataset",
                        fillColor: "rgba(220,220,220,0)",
                        strokeColor: "#13854f",
                        pointColor: "rgba(220,220,220,1)",
                        pointStrokeColor: "#fff",
                        pointHighlightFill: "#fff",
                        pointHighlightStroke: "rgba(220,220,220,1)",
                        data: data_quarter_values
                    }]
                };
                var halfyear = {
                    labels: data_year_dates,
                    datasets: [{
                        label: "Month dataset",
                        fillColor: "rgba(220,220,220,0)",
                        strokeColor: "#13854f",
                        pointColor: "rgba(220,220,220,1)",
                        pointStrokeColor: "#fff",
                        pointHighlightFill: "#fff",
                        pointHighlightStroke: "rgba(220,220,220,1)",
                        data: data_year_values
                    }]
                };


                var lineChart = new LineChartInit_relacje("myChart", all);
                $('a[data-chartfilter]').click(function (e) {
                    e.preventDefault();
                    var periodName = '', $parent;
                    $(this).closest('li').siblings().removeClass('current');
                    $(this).closest('li').addClass('current');
                    periodName = $(this).attr('data-chartfilter');
                    if (lineChart !== undefined) {
                        lineChart.destroy()
                    }
                    if (periodName === 'month') {
                        lineChart = new LineChartInit_relacje("myChart", month);
                    } else if (periodName === 'quarter') {
                        lineChart = new LineChartInit_relacje("myChart", quarter);
                    } else if (periodName === 'halfyear') {
                        lineChart = new LineChartInit_relacje("myChart", halfyear);
                    } else {
                        lineChart = new LineChartInit_relacje("myChart", all);
                    }
                });

            },
            finalize: function () {
            }
        },
        'akcjonariat': {
            init: function () {
            },
            finalize: function () {
            }
        },
        'raport_okresowy': {
            init: function () {

            },
            finalize: function () {
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);


    Chart.types.Line.extend({
        name: "LineChart",
        draw: function () {
            var scale = this.scale;
            scale.xScalePaddingLeft = 60;
            scale.xScalePaddingRight = 30;

            Chart.types.Line.prototype.draw.apply(this, arguments);

            for (var i = scale.xLabels.length - 1; i >= 0; i--) {
                this.chart.ctx.save();
                this.chart.ctx.fillStyle = '#e0e0e0';
                this.chart.ctx.beginPath();
                this.chart.ctx.arc(this.datasets[0].points[i].x, scale.endPoint, 5, 0, 2 * Math.PI);
                this.chart.ctx.closePath();
                this.chart.ctx.fill();
                this.chart.ctx.restore();
            }

            this.chart.ctx.beginPath();
            this.chart.ctx.moveTo(scale.xScalePaddingLeft, scale.endPoint);
            this.chart.ctx.strokeStyle = '#e0e0e0';
            this.chart.ctx.lineTo(scale.width, scale.endPoint);
            this.chart.ctx.stroke();
        }
    });

    Chart.types.Bar.extend({
        name: "BarOneTip",
        initialize: function (data) {
            Chart.types.Bar.prototype.initialize.apply(this, arguments);
        },
        getBarsAtEvent: function (e) {
            var barsArray = [],
            eventPosition = Chart.helpers.getRelativePosition(e),
            datasetIterator = function (dataset) {
                barsArray.push(dataset.bars[barIndex]);
            },
            barIndex;

            for (var datasetIndex = 0; datasetIndex < this.datasets.length; datasetIndex++) {
                for (barIndex = 0; barIndex < this.datasets[datasetIndex].bars.length; barIndex++) {
                    if (this.datasets[datasetIndex].bars[barIndex].inRange(eventPosition.x, eventPosition.y)) {

                        barsArray.push(this.datasets[datasetIndex].bars[barIndex]);
                        return barsArray;
                    }
                }
            }

            return barsArray;
        },
        showTooltip: function (ChartElements, forceRedraw) {
            if (typeof this.activeElements === 'undefined') this.activeElements = [];

            var isChanged = (function (Elements) {
                var changed = false;

                if (Elements.length !== this.activeElements.length) {
                    changed = true;
                    return changed;
                }

                Chart.helpers.each(Elements, function (element, index) {
                    if (element !== this.activeElements[index]) {
                        changed = true;
                    }
                }, this);
                return changed;
            }).call(this, ChartElements);

            if (!isChanged && !forceRedraw) {
                return;
            }
            else {
                this.activeElements = ChartElements;
            }
            this.draw();
            if (this.options.customTooltips) {
                this.options.customTooltips(false);
            }
            if (ChartElements.length > 0) {

                Chart.helpers.each(ChartElements, function (Element) {
                    var tooltipPosition = Element.tooltipPosition();
                    new Chart.Tooltip({
                        x: Math.round(tooltipPosition.x),
                        y: Math.round(tooltipPosition.y),
                        xPadding: this.options.tooltipXPadding,
                        yPadding: this.options.tooltipYPadding,
                        fillColor: this.options.tooltipFillColor,
                        textColor: this.options.tooltipFontColor,
                        fontFamily: this.options.tooltipFontFamily,
                        fontStyle: this.options.tooltipFontStyle,
                        fontSize: this.options.tooltipFontSize,
                        caretHeight: this.options.tooltipCaretSize,
                        cornerRadius: this.options.tooltipCornerRadius,
                        custom: this.options.customTooltips,
                        text: Chart.helpers.template(this.options.tooltipTemplate, Element),
                        chart: this.chart
                    }).draw();
                }, this);

            }
            return this;
        }

    });

    function BarChartInit(id, data) {
        var ctx = document.getElementById(id).getContext("2d");
        ctx.canvas.height = 300;
        var myBar = new Chart(ctx).BarOneTip(data, {
            animation: false,
            responsive: true,
            barValueSpacing: 15,
            barDatasetSpacing: 8,
            barShowStroke: false,
            tooltipCornerRadius: 0,
            scaleFontFamily: '"Roboto", sans-serif',
            tooltipFontFamily: '"Roboto", sans-serif',
            tooltipTitleFontFamily: '"Roboto", sans-serif',
            scaleShowGridLines: true,
            scaleLineColor: 'transparent',
            scaleShowVerticalLines: false,
            scaleBeginAtZero: true,
            legendTemplate: "<ul class=\"<%=name.toLowerCase()%>-legend\"><% for (var i=0; i<datasets.length; i++){%><li><span style=\"background-color:<%=datasets[i].fillColor%>\"></span><%if(datasets[i].label){%><%=datasets[i].label%><%}%></li><%}%></ul>",
            customTooltips: function (tooltip) {
                var tooltipEl = $('#' + id + '').siblings('.bar__tooltip');

                if (!tooltip) {
                    tooltipEl.css({
                        opacity: 0
                    });
                    return;
                }

                tooltipEl.removeClass('above below');
                tooltipEl.addClass('below');

                var customTool = [
                '<span class="bar__tooltip__text">' + tooltip.text.substr(0, tooltip.text.indexOf(':')) + '<br/>'
                + tooltip.text.substr(tooltip.text.indexOf(':') + 2, tooltip.text.length) + '</span>',
                ].join('');
                tooltipEl.html(customTool);

                tooltipEl.css({
                    opacity: 1,
                    left: tooltip.chart.canvas.offsetLeft + tooltip.x + 'px',
                    top: tooltip.chart.canvas.offsetTop + tooltip.y + 5 + 'px',
                    fontFamily: tooltip.fontFamily,
                    fontSize: tooltip.fontSize,
                    fontStyle: tooltip.fontStyle,
                });
            }
        });

        var legendHolder = $('#' + id + '__legend').append(myBar.generateLegend());
    }

    function LineChartInit(id, data) {
        var ctx = document.getElementById(id).getContext("2d");
        ctx.canvas.height = 300;
        var chartObject = new Chart(ctx).LineChart(data, {
            animation: false,
            pointDot: false,
            responsive: true,
            bezierCurve: false,
            tooltipFontFamily: '"Roboto", sans-serif',
            tooltipTitleFontFamily: '"Roboto", sans-serif',
            scaleFontFamily: '"Roboto", sans-serif',
            scaleShowGridLines: true,
            scaleLineColor: 'transparent',
            scaleShowVerticalLines: false,
            scaleBeginAtZero: true,
            scaleStepWidth: 50,
            scaleStartValue: -50,
            scaleLabel: function (d) {
                if (d.value < 0)
                    return '';
                else
                    return d.value + '         ';
            },
            customTooltips: function (tooltip) {
                var tooltipEl = $('#' + id + '').siblings('.line__tooltip'),
                lineEl = $('#' + id + '').siblings('.line__tooltip__line');

                if (!tooltip) {
                    tooltipEl.css({
                        opacity: 0
                    });
                    lineEl.css({
                        opacity: 0
                    });
                    return;
                }

                tooltipEl.removeClass('above below');
                tooltipEl.addClass('above');

                var customTool = [
                '<div class="line__tooltip__section">',
                '<span class="line__tooltip__title">' + tooltip.text.substr(0, tooltip.text.indexOf(':')) + '</span>',
                '<span class="line__tooltip__value">' + tooltip.text.substr(tooltip.text.indexOf(':') + 2, tooltip.text.length) + 'pln</span>',
                '</div>'
                ].join('');
                tooltipEl.html(customTool);

                tooltipEl.css({
                    opacity: 1,
                    left: tooltip.chart.canvas.offsetLeft + tooltip.x + 'px',
                    top: tooltip.chart.canvas.offsetTop + tooltip.y - tooltipEl.height() - 28 + 'px',
                    fontFamily: tooltip.fontFamily,
                    fontSize: tooltip.fontSize,
                    fontStyle: tooltip.fontStyle,
                });
                lineEl.css({
                    opacity: 1,
                    left: tooltip.chart.canvas.offsetLeft + tooltip.x + 'px',
                    top: tooltip.chart.canvas.offsetTop + 'px',
                    height: tooltip.chart.canvas.height + 'px'
                });
            }
        });

        return chartObject;
    };

    function LineChartInit_relacje(id, data) {
        var ctx = document.getElementById(id).getContext("2d");
        ctx.canvas.height = 300;

        var values = data.datasets[0].data;

        var max = Math.max.apply(Math, values) + 0.2 ;
        var min = Math.min.apply(Math, values) - 0.2 ;
        var steps = 5;
        var width = parseFloat( (max - min) / steps ).toFixed(2);

        var chartObject = new Chart(ctx).LineChart(data, {
            animation: false,
            pointDot: false,
            responsive: true,
            bezierCurve: false,
            tooltipFontFamily: '"Roboto", sans-serif',
            tooltipTitleFontFamily: '"Roboto", sans-serif',
            scaleFontFamily: '"Roboto", sans-serif',
            scaleShowGridLines: true,
            scaleLineColor: 'transparent',
            scaleShowVerticalLines: false,
            scaleBeginAtZero: false,
            showXLabels: 20,
            scaleOverride: true,
            scaleSteps: steps,
            scaleStepWidth: width,
            scaleStartValue: min,
            scaleLabel: function (d) {
                if (d.value < 0)
                    return '';
                else
                    return d.value + ' ';
            },
            customTooltips: function (tooltip) {
                var tooltipEl = $('#' + id + '').siblings('.line__tooltip'),
                lineEl = $('#' + id + '').siblings('.line__tooltip__line');

                if (!tooltip) {
                    tooltipEl.css({
                        opacity: 0
                    });
                    lineEl.css({
                        opacity: 0
                    });
                    return;
                }

                tooltipEl.removeClass('above below');
                tooltipEl.addClass('above');

                var customTool = [
                '<div class="line__tooltip__section">',
                '<span class="line__tooltip__title">' + tooltip.text.substr(0, tooltip.text.indexOf(':')) + '</span>',
                '<span class="line__tooltip__value">' + tooltip.text.substr(tooltip.text.indexOf(':') + 2, tooltip.text.length) + 'pln</span>',
                '</div>'
                ].join('');
                tooltipEl.html(customTool);

                tooltipEl.css({
                    opacity: 1,
                    left: tooltip.chart.canvas.offsetLeft + tooltip.x + 'px',
                    top: tooltip.chart.canvas.offsetTop + tooltip.y - tooltipEl.height() - 28 + 'px',
                    fontFamily: tooltip.fontFamily,
                    fontSize: tooltip.fontSize,
                    fontStyle: tooltip.fontStyle,
                });
                lineEl.css({
                    opacity: 1,
                    left: tooltip.chart.canvas.offsetLeft + tooltip.x + 'px',
                    top: tooltip.chart.canvas.offsetTop + 'px',
                    height: tooltip.chart.canvas.height + 'px'
                });
            }
        });

        return chartObject;
    };


    function DoughnutChartInit(id, data) {
        var datasum = 0;
        for (var i = data.length - 1; i >= 0; i--) {
            datasum += data[i].value;
        }

        var ctx = document.getElementById(id).getContext("2d"),
        myPieChart = new Chart(ctx).Doughnut(data, {
            animation: false,
            percentageInnerCutout: 75,
            responsive: true,
            scaleFontFamily: '"Roboto", sans-serif',
            tooltipFontFamily: '"Roboto", sans-serif',
            tooltipTitleFontFamily: '"Roboto", sans-serif',
            scaleShowGridLines: true,
            legendTemplate: "<ul class=\"<%=name.toLowerCase()%>__legend\"><% for (var i=0; i<segments.length; i++){%><li><span style=\"background-color:<%=segments[i].fillColor%>\"></span><%if(segments[i].label){%><%=segments[i].label%><%}%></li><%}%></ul>",

            customTooltips: function (tooltip) {

                var tooltipEl = $('#' + id + '').siblings('.donaught__tooltip'),
                centerEl = $('#' + id + '').siblings('.donaught__tooltip__value');

                if (!tooltip) {
                    return;
                }
                tooltipEl.removeClass('above below');
                tooltipEl.addClass('above');

                var customTool = [
                '<span class="donaught__tooltip__title">' + tooltip.text.substr(0, tooltip.text.indexOf(':')) + '</span>',
                ].join('');
                tooltipEl.html(customTool);
                centerEl.html('<span class="dTab"><span class="dCell">' + tooltip.text.substr(tooltip.text.indexOf(':') + 2, tooltip.text.length) + '%</span></span>');

                tooltipEl.css({
                    opacity: 1,
                    left: tooltip.chart.canvas.offsetLeft + tooltip.x + 'px',
                    top: tooltip.chart.canvas.offsetTop + tooltip.y + 'px',
                    fontFamily: tooltip.fontFamily,
                });
            },
        });

        var legendHolder = $('#' + id + '__legend').append(myPieChart.generateLegend());
        Chart.helpers.each(legendHolder.find('li'), function (legendNode, index) {
            Chart.helpers.addEvent(legendNode, 'mouseover', function () {
                var activeSegment = myPieChart.segments[index];
                activeSegment.save();
                activeSegment.fillColor = activeSegment.highlightColor;
                myPieChart.showTooltip([activeSegment]);
                activeSegment.restore();
            });
        });
        Chart.helpers.addEvent(legendHolder.children(), 'mouseout', function () {
            myPieChart.draw();
        });
    };


    function CurrentRaportsFiltering() {
        var hiddenInput = $('<input type="hidden" id="date-range"/>').appendTo('body');
        var _arr = ['1800-01-01', '3000-02-02'];
        hiddenInput.val(_arr[0] + ',' + _arr[1]);

        $("#date-from").bind( 'change,submit', function () {
            if ($(this).val() == '') {
                _arr[0] = ['1800-01-01']
            } else {
                _arr[0] = $(this).val();
            }
            hiddenInput.val(_arr[0] + ',' + _arr[1]).trigger('change');
        });

        $('#date-to').bind( 'change, submit', function () {
            if ($(this).val() == '') {
                _arr[1] = ['3000-01-01']
            } else {
                _arr[1] = $(this).val();
            }
            hiddenInput.val(_arr[0] + ',' + _arr[1]).trigger('change');
        });

        var FJS = FilterJS(currentRaports, "#currentraport__list", {
            template: '#currentraport__template',
            search: { ele: '#search-input' },
            pagination: {
                container: '#pagination',
                visiblePages: 5,
                perPage: {
                    values: [15],
                    container: '#per_page'
                },
            }
        });

        FJS.addCriteria({ field: 'date', ele: '#date-range', type: 'range', delimiter: ',' });

        window.FJS = FJS;
    };

    function PeriodicRaportsFiltering() {
        var hiddenInput = $('<input type="hidden" id="date-range"/>').appendTo('body');
        var _arr = ['1800-01-01', '3000-02-02'];
        hiddenInput.val(_arr[0] + ',' + _arr[1]);

        $("#date-from").bind( 'change,submit',function () {
            if ($(this).val() == '') {
                _arr[0] = ['1800-01-01']
            } else {
                _arr[0] = $(this).val();
            }
            hiddenInput.val(_arr[0] + ',' + _arr[1]).trigger('change');
        });

        $('#date-to').bind( 'change,submit',function () {
            if ($(this).val() == '') {
                _arr[1] = ['3000-01-01']
            } else {
                _arr[1] = $(this).val();
            }
            hiddenInput.val(_arr[0] + ',' + _arr[1]).trigger('change');
        });

        var FJS = FilterJS(periodicRaports, "#periodicraport__list", {
            template: '#periodicraport__template',
            search: { ele: '#search-input' },
            pagination: {
                container: '#pagination',
                visiblePages: 5,
                perPage: {
                    values: [15],
                    container: '#per_page'
                },
            }
        });

        FJS.addCriteria({ field: 'date', ele: '#date-range', type: 'range', delimiter: ',' });
        FJS.addCriteria({ field: 'type', ele: '#type-multiselect :checkbox', all: "all" });

        window.FJS = FJS;
    };


    function FinancialInformationFiltering() {
        var hiddenInput = $('<input type="hidden" id="date-range"/>').appendTo('body');
        var _arr = ['1800', '3000'];
        hiddenInput.val(_arr[0] + ',' + _arr[1]);

        $("#date-from").bind( 'change', function () {
            if ($(this).val() == '') {
                _arr[0] = ['1800']
            } else {
                _arr[0] = $(this).val().toString();
            }

            $('.table--financialinfo th[data-year]').each(function () {
                var _val = $(this).attr('data-year');
                if (_val > _arr[1] || _val < _arr[0]) {
                    $('[data-year="' + _val + '"]').hide();
                } else {
                    $('[data-year="' + _val + '"]').show();
                }
            });
        });

        $('#date-to').bind( 'change',function () {
            if ($(this).val() == '') {
                _arr[1] = ['3000']
            } else {
                _arr[1] = $(this).val().toString();
            }
            $('.table--financialinfo th[data-year]').each(function () {
                var _val = $(this).attr('data-year');
                if (_val > _arr[1] || _val < _arr[0]) {
                    $('[data-year="' + _val + '"]').hide();
                } else {
                    $('[data-year="' + _val + '"]').show();
                }
            });
        });

        var FJS = FilterJS(financials, "#financial__information", {
            template: '#financial__template',
            search: { ele: '#search-input', field: 'title' }
        });

        window.FJS = FJS;
    };


    function RelationsFiltering() {

        var _i, _j;
        function HideElements() {
            _i = 0, _j = 0;
            $('#periodicraport__list > li').each(function () {
                if ($(this).css('display') != 'none') {
                    if (_i < 3) {
                        _i++;
                    } else {
                        $(this).css('display', 'none');
                    }
                }
            });

            $('#currentraport__list > li').each(function () {
                if ($(this).css('display') != 'none') {
                    if (_j < 3) {
                        _j++;
                    } else {
                        $(this).css('display', 'none');
                    }
                }
            });
        }

        var FJS = FilterJS(periodicRaports, "#periodicraport__list", {
            template: '#periodicraport__template',
            callbacks: {
                afterAddRecords: HideElements,
                afterFilter: HideElements
            },
            search: { ele: '#search-input', fields: ["title"] }
        });

        FJS.setTemplate('#currentraport__template');
        FJS.$container = $('#currentraport__list');
        FJS.addRecords(currentRaports);

        window.FJS = FJS;
    };

    function DateTimePickerInit(format, language) {

    }


})(jQuery); // Fully reference jQuery after this point.
